<template>
  <div class="main">
    <div class="empty">
      <van-icon name="close" @click="onCloseVideoWidget" />
    </div>
    <video
      id="video"
      ref="player"
      style="width:100%"
      preload="auto"
      autoplay
      controls
      playsinline
      webkit-playsinline
      @touchstart="touchEnterVideo"
    ></video>
    <!-- <div class="video-container">
            <div class="top-player">
                <video-player
                    class="video-player-box vjs-big-play-centered"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname"
                >
                </video-player>
            </div>
        </div> -->
    <!-- <div class="control" @click="onSpeed" v-if="controlShow">
            <div class="speed">{{ speed }}</div>
            <div>x</div>
        </div> -->
    <div class="empty"></div>
  </div>
</template>

<script>
const QUERY_INTERVAL = 60000; // 60秒
//const QUERY_INTERVAL = 6000; // 60秒

import { teachApi } from "@/api/teachApi.js";
import { userLearnTimeApi } from "@/api/userLearnTimeApi.js";
import { isMobile, isWeiXin } from "@/util/browser.js";
export default {
  components: {
    //videoPlayer,
  },
  props: {
    //vodParam: Object,
    courseId: String,
    scheduleId: String,
    teachId: String,
    vcode: String,
  },
  data() {
    return {
      videoId: "video",
      player: null,
      speed: 1,
      controlShow: false,
      vodParam: {},
      clock: null,
      prevCurrentTime: 0,

      dogeVodParam: {},
    };
  },
  methods: {
    onCloseVideoWidget() {
      this.$EventBus.$emit("onCloseVideoWidget", true);
    },
    initVideo() {
      if (this.vcode) {
        const isWeiXinVal = isWeiXin();
        const isMobileVal = isMobile();
        const isMobileMode = isWeiXinVal || isMobileVal;
        let platform = "pch5";
        if (isMobileMode) {
          platform = "wap";
        }
        teachApi.dogeCloudVodUrl(this.courseId, this.scheduleId, this.teachId, platform).then((vodRet) => {
          if (vodRet.code == 0 && vodRet.data) {
            this.dogeVodParam = vodRet.data;
            this.player = new this.$TcPlayer(this.videoId, {
              playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            });
            this.player.src(this.dogeVodParam.stream[0].url); // url 播放地址
            console.log("vcode", this.vcode, this.dogeVodParam);
          }
        });
      } else {
        teachApi.vodParam(this.courseId, this.scheduleId, this.teachId).then((ret) => {
          if (ret.code == 0 && ret.data) {
            console.log("vodParam", ret);
            this.vodParam = ret.data;
            this.player = new this.$TcPlayer(this.videoId, {
              playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
              //...this.vodParam,
              fileID: this.vodParam.fileID,
              appID: this.vodParam.appID,
              psign: this.vodParam.psign,
            });
          }
        });
      }
    },
    touchEnterVideo() {
      console.log("touchEnterVideo");
      this.controlShow = true;
      let that = this;
      setTimeout(() => {
        if (that.controlShow) {
          that.controlShow = false;
        }
      }, 10000);
    },
    onSpeed() {
      this.speed += 0.25;
      if (this.speed > 2) {
        this.speed = 0.5;
      }
      let videoObj = this.$refs.player;
      if (videoObj) {
        videoObj.playbackRate = this.speed;
      }
    },
    onKeydown(e) {
      if (e.code == "ArrowRight") {
        console.log("right..");
        let videoObj = this.$refs.player;
        if (videoObj) {
          videoObj.currentTime += 10; // 单位是秒
        }
      } else if (e.code == "ArrowLeft") {
        console.log("left...");
        let videoObj = this.$refs.player;
        if (videoObj) {
          videoObj.currentTime -= 10; // 单位是秒
        }
      }
    },
    addLearnTime() {
      let videoObj = this.$refs.player;
      if (videoObj && videoObj.currentTime != this.prevCurrentTime) {
        let item = {
          courseId: this.courseId,
          scheduleId: this.scheduleId,
          teachId: this.teachId,
          userAction: 0, // vod
        };
        console.log("add learn time");
        userLearnTimeApi.add(item);
        this.prevCurrentTime = videoObj.currentTime;
      }
    },
  },

  mounted() {
    //console.log('mounted', this.courseId, this.teachId);
    this.initVideo();
    this.$nextTick(() => {
      this.clock = setInterval(() => {
        this.addLearnTime();
      }, QUERY_INTERVAL);
    });
    this.$once("hook:beforeDestory", function() {
      clearInterval(this.clock);
      this.clock = null;
    });
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
  },

  created: function() {
    window.addEventListener("keydown", this.onKeydown);
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
    }
    window.removeEventListener("keydown", this.onKeydown);
  },

  watch: {
    vodParam: function(val) {
      console.log("watch vodParam", val);
    },
    fileId: function(val) {
      console.log("fileId", val);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  .video-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top-player {
    }
  }
  .empty {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .van-icon {
      margin: 12px;
      font-size: 40px;
      color: #fff;
    }
  }
  .control {
    position: absolute;
    width: 20px;
    height: 40px;
    right: 5px;
    bottom: 50%;
    z-index: 21;
    border-radius: 6px;
    font-size: 11px;
    color: #ffffff;
    border: 1px solid #4d52d1;
    background: #4d52d1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
